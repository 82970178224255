import React, { useContext } from "react";

/// React router dom
import { Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
/// Dashboard
// import Concierge from "./components/Dashboard/Concierge";
import DashboardDark from "./components/Dashboard/DashboardDark";
// import GuestDetail from "./components/Dashboard/GuestDetail";
// import GuestList from "./components/Dashboard/GuestList";
// import Home from "./components/Dashboard/Home";
// import Reviews from "./components/Dashboard/Reviews";
// import Room from "./components/Dashboard/Room";
// import Task from "./components/Dashboard/Task";

/////Demo pages
// import Demo1 from './components/Dashboard/Demo/Demo1';
// import Demo2 from './components/Dashboard/Demo/Demo2';
// import Demo3 from './components/Dashboard/Demo/Demo3';
// import Demo4 from './components/Dashboard/Demo/Demo4';
// import Demo5 from './components/Dashboard/Demo/Demo5';
//import Demo6 from './components/Dashboard/Demo/Demo6';

/// App
// import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
// import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
// import Calendar from "./components/AppsMenu/Calendar/Calendar";
// import Compose from "./components/AppsMenu/Email/Compose/Compose";
// import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
// import Read from "./components/AppsMenu/Email/Read/Read";

/// Product List
// import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Customers from "./components/AppsMenu/Shop/Customers/Customers";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
// import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
// import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";

/// Charts
// import ChartJs from "./components/charts/Chartjs";
// import SparklineChart from "./components/charts/Sparkline";
// import ApexChart from "./components/charts/apexcharts";
// import Chartist from "./components/charts/chartist";
// import RechartJs from "./components/charts/rechart";

/// Bootstrap
// import UiAccordion from "./components/bootstrap/Accordion";
// import UiAlert from "./components/bootstrap/Alert";
// import UiBadge from "./components/bootstrap/Badge";
// import UiButton from "./components/bootstrap/Button";
// import UiButtonGroup from "./components/bootstrap/ButtonGroup";
// import UiCards from "./components/bootstrap/Cards";
// import UiCarousel from "./components/bootstrap/Carousel";
// import UiDropDown from "./components/bootstrap/DropDown";
// import UiGrid from "./components/bootstrap/Grid";
// import UiListGroup from "./components/bootstrap/ListGroup";
// import UiMediaObject from "./components/bootstrap/MediaObject";
// import UiModal from "./components/bootstrap/Modal";
// import UiPagination from "./components/bootstrap/Pagination";
// import UiPopOver from "./components/bootstrap/PopOver";
// import UiProgressBar from "./components/bootstrap/ProgressBar";
// import UiTab from "./components/bootstrap/Tab";
// import UiTypography from "./components/bootstrap/Typography";

/// Plugins
// import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
// import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
// import Nestable from "./components/PluginsMenu/Nestable/Nestable";
// import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
// import Select2 from "./components/PluginsMenu/Select2/Select2";
// import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
// import Toastr from "./components/PluginsMenu/Toastr/Toastr";

//Redux
// import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
// import WizardForm from "./components/Forms/ReduxWizard/Index";
// import Todo from "./pages/Todo";

/// Widget
// import Widget from "./pages/Widget";

/// Table
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";
// import SortingTable from "./components/table/SortingTable/SortingTable";
// import DataTable from "./components/table/PopCards";
import PopCards from "./components/table/PopCards";
import Agent from "./components/table/Agent";
// import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import AddPopcard from "./components/Forms/Element/AddPopcard";
import AddAgent from "./components/Forms/Element/AddAgent";
import Clone from "./components/Forms/Element/Clone";
// import Element from "./components/Forms/Element/Element";
// import Pickers from "./components/Forms/Pickers/Pickers";
// import SummerNote from "./components/Forms/Summernote/SummerNote";
// import Wizard from "./components/Forms/Wizard/Wizard";
// import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import { ThemeContext } from "../context/ThemeContext";
import AddMember from "./components/Forms/Element/AddMember";
import Members from "./components/table/Members";
import Setting from "./layouts/Setting";
// import Error400 from "./pages/Error400";
// import Error403 from "./pages/Error403";
// import Error404 from "./pages/Error404";
// import Error500 from "./pages/Error500";
// import Error503 from "./pages/Error503";
// import ForgotPassword from "./pages/ForgotPassword";
// import LockScreen from "./pages/LockScreen";
// import Login from "./pages/Login";
// import Registration from "./pages/Registration";
import EditPopcard from "./components/Forms/Element/EditPopcard";
import EditAgent from "./components/Forms/Element/EditAgent";
import Logs from "./components/table/Logs";


const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: DashboardDark },
    // { url: "dashboard", component: Home },
    // { url: "dashboard-dark", component: DashboardDark },
    // { url: "guest-list", component: GuestList },
    // { url: "guest-detail", component: GuestDetail },
    // { url: "concierge", component: Concierge },
    // { url: "room-list", component: Room },
    // { url: "reviews", component: Reviews },
    // { url: "task", component: Task },

    ///themes 
    // { url: "theme1", component: Demo1 },
    // { url: "theme2", component: Demo2 },
    // { url: "theme3", component: Demo3 },
    // { url: "theme4", component: Demo4 },
    // { url: "theme5", component: Demo5 },
    // { url: "theme6", component: Demo6 },


    /// Apps
    // { url: "app-profile", component: AppProfile },
    // { url: "email-compose", component: Compose },
    // { url: "email-inbox", component: Inbox },
    // { url: "email-read", component: Read },
    // { url: "app-calender", component: Calendar },
    // { url: "post-details", component: PostDetails },

    /// Chart
    // { url: "chart-sparkline", component: SparklineChart },
    // { url: "chart-chartjs", component: ChartJs },
    // { url: "chart-chartist", component: Chartist },
    // { url: "chart-apexchart", component: ApexChart },
    // { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    // { url: "ui-alert", component: UiAlert },
    // { url: "ui-badge", component: UiBadge },
    // { url: "ui-button", component: UiButton },
    // { url: "ui-modal", component: UiModal },
    // { url: "ui-button-group", component: UiButtonGroup },
    // { url: "ui-accordion", component: UiAccordion },
    // { url: "ui-list-group", component: UiListGroup },
    // { url: "ui-media-object", component: UiMediaObject },
    // { url: "ui-card", component: UiCards },
    // { url: "ui-carousel", component: UiCarousel },
    // { url: "ui-dropdown", component: UiDropDown },
    // { url: "ui-popover", component: UiPopOver },
    // { url: "ui-progressbar", component: UiProgressBar },
    // { url: "ui-tab", component: UiTab },
    // { url: "ui-pagination", component: UiPagination },
    // { url: "ui-typography", component: UiTypography },
    // { url: "ui-grid", component: UiGrid },

    /// Plugin
    // { url: "uc-select2", component: Select2 },
    // { url: "uc-nestable", component: Nestable },
    // { url: "uc-noui-slider", component: MainNouiSlider },
    // { url: "uc-sweetalert", component: MainSweetAlert },
    // { url: "uc-toastr", component: Toastr },
    // { url: "map-jqvmap", component: JqvMap },
    // { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    // { url: "todo", component: Todo },
    // { url: "redux-form", component: ReduxForm },
    // { url: "redux-wizard", component: WizardForm },

    /// Widget
    // { url: "widget-basic", component: Widget },

    /// Shop
    // { url: "ecom-product-grid", component: ProductGrid },
    // { url: "ecom-product-list", component: ProductList },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-product-order", component: ProductOrder },
    // { url: "ecom-checkout", component: Checkout },
    // { url: "ecom-invoice", component: Invoice },
    // { url: "ecom-product-detail", component: ProductDetail },
    // { url: "ecom-customers", component: Customers },

    /// Form
    // { url: "form-element", component: Element },
    // { url: "form-wizard", component: Wizard },
    // { url: "form-editor-summernote", component: SummerNote },
    // { url: "form-pickers", component: Pickers },
    // { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    // { url: 'table-filtering', component: FilteringTable },
    // { url: 'table-sorting', component: SortingTable },
    // { url: "table-datatable-basic", component: DataTable },
    // { url: "table-bootstrap-basic", component: BootstrapTable },

    // POPCARD 
    { url: "PopCards", component: PopCards },
    { url: "AddPopcard", component: AddPopcard },
    { url: "EditPopcard/:id", component: EditPopcard },
    { url: "members", component: Members },
    { url: "agents", component: Agent },
    { url: "add-agent", component: AddAgent },
    { url: "edit-agent/:id", component: EditAgent },
    { url: "AddMember", component: AddMember },
    { url: "Clone", component: Clone },
    { url: "logs", component: Logs },

    /// pages
    // { url: "page-register", component: Registration },
    // { url: "page-lock-screen", component: LockScreen },
    // { url: "page-login", component: Login },
    // { url: "page-forgot-password", component: ForgotPassword },
    // { url: "page-error-400", component: Error400 },
    // { url: "page-error-403", component: Error403 },
    // { url: "page-error-404", component: Error404 },
    // { url: "page-error-500", component: Error500 },
    // { url: "page-error-503", component: Error503 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={<data.component />}
                />
              ))}
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
