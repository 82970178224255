import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
// import DeleteIcon from "@material-ui/icons/Delete";
import axiosInstance from "../../../../services/AxiosInstance";
import ImageUploader from "../../ImgCropUploader";

// const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const AddPopcard = () => {
  const navigate = useNavigate();
  const [loginUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [, setIsCamera] = useState(false);
  const [authservice, setauthService] = useState([]);
  const [loader, setLoader] = useState(false);
  const [popsinger, setPopsinger] = useState([{}]);
  const [agents, setAgents] = useState([]);
  // ADD POPCARD STATES
  const [stickernumber, setStickernumber] = useState("");
  const [popcardcolor, setPopcardcolor] = useState("");
  const [status, setStatus] = useState("authenticated");
  const [locationsigned, setLocationsigned] = useState("");
  const [datesigned, setDatesigned] = useState("");
  const [signaturetype, setSignaturetype] = useState("");
  const [encapsulation, setEncapsulation] = useState("");
  const [graded, setGraded] = useState("");
  const [gradenum, setGradenum] = useState("");
  const [gradecomp, setGradecomp] = useState("");

  const [funkopopseries, setFunkopopseries] = useState("");
  const [funkopopnumber, setFunkopopnumber] = useState("");
  const [funkopopname, setFunkopopname] = useState("");
  const [upcnumber, setUpcnumber] = useState("");
  const [boxdatecode, setBoxdatecode] = useState("");
  const [signer, setSigner] = useState([]);
  const [authenticationwitness, setAuthenticationwitness] = useState(
    loginUser.id
  );
  const [gpsdata, setGpsdata] = useState("verified");

  const [signaffidavit, setSignaffidavit] = useState("");
  const [videoorclip, setVideoorclip] = useState("");
  const [gradingpic, setGradingpic] = useState("");

  const [authenticationguarentee, setAuthenticationGuarentee] = useState("");
  const [rightimagebordorcolor, setRightimagebordorcolor] = useState("");
  const [limit, setLimit] = useState("1");
  const [owner, setOwner] = useState("");
  const [purdate, setPurdate] = useState("");
  const [purprice, setPurprice] = useState("");
  const [notes, setNotes] = useState("");

  const [leftimagebordorcolor, setLeftimagebordorcolor] = useState("");
  const [packagename, setPackagenme] = useState("");

  const [facingMode, setFacingMode] = useState([
    {
      facingMode: FACING_MODE_ENVIRONMENT,
      width: 540,
      height: 720,
    },
  ]);

  useEffect(() => {
    GetAgents();
  }, []);

  const GetAgents = () => {
    setLoader(true);
    axiosInstance
      .get("agent")
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setAgents(response.data.data);
        } else {
          if (response.data.message === "") {
            console.log("else check msg");
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  const Authservics = (value) => {
    setPackagenme(value);
    let items =
      value === "silver"
        ? [
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
          ]
        : value === "gold"
        ? [
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
          ]
        : value === "vip"
        ? [
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
            {
              facingMode: FACING_MODE_ENVIRONMENT,
              width: 540,
              height: 720,
            },
          ]
        : [];
    // console.log("item", items)
    setauthService(items);
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    multiValue: (styles, { data }) => {
      // const color = '#e31990';
      return {
        ...styles,
        backgroundColor: "#e31990",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#e31990",
        color: "white",
      },
    }),
  };

  const Additem = () => {
    const newItem = {
      facingMode: FACING_MODE_ENVIRONMENT,
      width: 540,
      height: 720,
    };
    setPopsinger([...popsinger, {}]);
    setFacingMode((prevState) => [...prevState, newItem]);
  };

  const handleRemoveClick = (index) => {
    popsinger.splice(index, 1);
    setPopsinger([...popsinger]);

    facingMode.splice(index, 1);
    setFacingMode([...facingMode]);
  };

  const handleRemovePopSignerImage = (index) => {
    setPopsinger((prevState) => {
      const updatedPopSinger = [...prevState];
      updatedPopSinger[index] = {
        ...prevState[index],
        file: prevState[index]?.file === "",
        type: prevState[index]?.type === "",
      };
      return updatedPopSinger;
    });
    if (popsinger?.length === 1) {
      setIsCamera(false);
    }
    console.log(popsinger, "length-------->>>");
  };

  const handleRemoveClick1 = (index) => {
    //  authservice.splice(index, 1);
    //  setauthService([...authservice]);

    setauthService((prevState) => {
      const updatedAuthService = [...prevState];
      updatedAuthService[index] = {
        ...prevState[index],
        file: prevState[index]?.file === "",
        type: prevState[index]?.type === "",
      };
      return updatedAuthService;
    });
  };

  const FileUpload = (e, index) => {
    // setPopsinger({...popsinger,index:e.target.files[0]  })
    const value = e.file.originFileObj;
    // Create a copy of the state array
    const updatedState = [...popsinger];
    // Update the specific object in the array
    updatedState[index] = { ...updatedState[index], file: value };
    // Update the state with the modified array
    setPopsinger(updatedState);
  };

  const FileUpload2 = (e, index) => {
    // setPopsinger({...popsinger,index:e.target.files[0]  })
    const value = e.file.originFileObj;
    // Create a copy of the state array
    const updatedState = [...authservice];
    // Update the specific object in the array
    updatedState[index] = { ...updatedState[index], file: value };
    // console.log("setauthService", updatedState)
    // Update the state with the modified array
    setauthService(updatedState);
  };

  const AddPopCard = () => {
    setLoader(true);
    const form = new FormData();
    form.append("stickerNumber", stickernumber);
    form.append("popcard", "POP CARD");
    form.append("forPass", "P.A.S.S");
    form.append("popcardColor", popcardcolor);
    form.append("authenticationService", packagename);
    form.append("status", status);
    form.append("locationSigned", locationsigned);
    form.append("dateSigned", datesigned);
    form.append("signatureType", signaturetype);
    form.append("funkoPopSeries", funkopopseries);
    form.append("funkoPopNumber", funkopopnumber);
    form.append("funkoPopName", funkopopname);
    form.append("upcNumber", upcnumber);
    form.append("boxDateCode", boxdatecode);
    const a = signer.map((e) => {
      return e.value;
    });
    form.append("signer", a);
    form.append("authentication_witness_id", authenticationwitness);
    form.append("signAffidavit", signaffidavit);
    form.append("videoOrClip", videoorclip);
    form.append("authenticationGuarentee", authenticationguarentee);

    if (packagename === "silver") {
      form.append("serviceOneImage", authservice[0]?.file || "");
    } else if (packagename === "gold") {
      form.append("serviceOneImage", authservice[0]?.file || "");
      form.append("serviceTwoImage", authservice[1]?.file || "");
      // form.append("serviceThreeImage", authservice[2]?.file)
    } else {
      form.append("serviceOneImage", authservice[0]?.file || "");
      form.append("serviceTwoImage", authservice[1]?.file || "");
      form.append("serviceThreeImage", authservice[2]?.file || "");
      form.append("serviceFourImage", authservice[3]?.file || "");
      form.append("serviceFiveImage", authservice[4]?.file || "");
      form.append("serviceSixImage", authservice[5]?.file || "");
    }

    popsinger.map((e) => form.append("popSigner[]", e.file));
    form.append("leftImageBordorColor", leftimagebordorcolor);
    form.append("rightImageBordorColor", rightimagebordorcolor);
    form.append("limit", limit);
    form.append("gpsMetaData", gpsdata);
    form.append("encapsulated", encapsulation);
    form.append("graded", graded);
    form.append("gradeNumber", gradenum);
    form.append("gradingCompany", gradecomp);
    form.append("gradingReportLink", gradingpic);
    form.append("note", notes);

    axiosInstance
      .post("popcard", form)
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/PopCards");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
        setLoader(false);
      });
  };

  const handleInputChange = (e, func) => {
    func(e.target.value);
  };

  const hanldeWebCam = async (imageSrc, i, func, state) => {
    const base64Response = await fetch(imageSrc);
    const blob = await base64Response.blob();

    const updatedState = [...state];
    // Update the specific object in the array
    updatedState[i] = {
      ...updatedState[i],
      file: blob,
    };
    // console.log("setauthService", updatedState)
    // Update the state with the modified array
    func(updatedState);
    setIsCamera(false);
    // console.log(imageSrc);
  };

  const handleUploadType = (e, i, func, state, type) => {
    // if (isCamera && type === "camera") {
    //   return toast.warning("Please capture picture first");
    // }
    if (type === "camera") {
      setIsCamera(true);
    }
    const { value } = e.target;
    const updatedState = [...state];

    updatedState[i] = {
      ...updatedState[i],
      type: value,
    };

    func(updatedState);
  };

  // const handleClick1 = React.useCallback((index) => {
  //   setauthService((prevState) => {
  //     const updatedFacingMode = [...prevState];
  //     updatedFacingMode[index] = {
  //       ...prevState[index],
  //       facingMode:
  //         prevState[index]?.facingMode === FACING_MODE_USER
  //           ? FACING_MODE_ENVIRONMENT
  //           : FACING_MODE_USER,
  //     };
  //     return updatedFacingMode;
  //   });
  // }, []);

  // const handleClick = React.useCallback((index) => {
  //   setFacingMode((prevState) => {
  //     const updatedFacingMode = [...prevState];
  //     updatedFacingMode[index] = {
  //       ...prevState[index],
  //       facingMode:
  //         prevState[index]?.facingMode === FACING_MODE_USER
  //           ? FACING_MODE_ENVIRONMENT
  //           : FACING_MODE_USER,
  //     };
  //     return updatedFacingMode;
  //   });
  // }, []);

  // console.log(isCamera, "camera ");
  // console.log(popsinger, "popsigner");

  return (
    <Fragment>
      {/* <PageTItle activeMenu="Element" motherMenu="Form" pageContent="Element" /> */}
      {loader === true ? <Loader fullPage loading /> : null}

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                ADD POP CARD
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row align-items-end">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        STICKER NUMBER
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="S - 100250"
                        onChange={(e) => handleInputChange(e, setStickernumber)}
                        value={stickernumber}
                      /> */}
                      <InputMask
                        className="form-control"
                        placeholder="Enter Sticker Number"
                        mask="a-999999"
                        onChange={(e) => handleInputChange(e, setStickernumber)}
                        value={stickernumber}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        POP CARD COLOR
                      </label>
                      <input
                        type="color"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setPopcardcolor)}
                        value={popcardcolor}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        POP PICTURE BORDER COLOR
                      </label>
                      <input
                        type="color"
                        className="form-control"
                        onChange={(e) =>
                          handleInputChange(e, setLeftimagebordorcolor)
                        }
                        value={leftimagebordorcolor}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        POP SIGNER BORDER COLOR
                      </label>
                      <input
                        type="color"
                        className="form-control"
                        onChange={(e) =>
                          handleInputChange(e, setRightimagebordorcolor)
                        }
                        value={rightimagebordorcolor}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        AUTHENTICATION SERVICE
                      </label>
                      {/* <Select options={options} onChange={(e) => Authservics(e.value)} styles={{ background: 'linear-gradient(to right, #1d2333 0%, #454e69 100%)' }} /> */}
                      <select
                        onChange={(e) => Authservics(e.target.value)}
                        id="inputState"
                        className="form-control form-control-select"
                      >
                        <option value="options">
                          Please select one option
                        </option>
                        <option value={"silver"}>Silver</option>
                        <option value={"gold"}>Gold</option>
                        <option value={"vip"}>VIP</option>
                      </select>
                    </div>
                    {authservice.map((v, i) => (
                      <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                        <label
                          className="text-white"
                          style={{ fontSize: "medium" }}
                        >
                          POP PICTURE {i + 1}
                        </label>
                        <div className="d-flex align-items-center mb-3">
                          <div className="form-group text-center">
                            {!v.type ? (
                              <>
                                <Button
                                  className="me-2"
                                  variant="primary"
                                  value={"camera"}
                                  onClick={(e) =>
                                    handleUploadType(
                                      e,
                                      i,
                                      setauthService,
                                      authservice,
                                      "camera"
                                    )
                                  }
                                >
                                  camera
                                </Button>
                                <Button
                                  className="me-2"
                                  variant="primary"
                                  value={"media"}
                                  onClick={(e) =>
                                    handleUploadType(
                                      e,
                                      i,
                                      setauthService,
                                      authservice,
                                      "media"
                                    )
                                  }
                                >
                                  media
                                </Button>
                              </>
                            ) : null}

                            {v.type === "camera" ? (
                              <>
                                {!v.file && (
                                  <Webcam
                                    audio={false}
                                    // height={720}
                                    screenshotFormat="image/jpeg"
                                    width={200}
                                    mirrored={
                                      authservice[i]?.facingMode ===
                                      "environment"
                                        ? false
                                        : true
                                    }
                                    videoConstraints={authservice[i]}
                                  >
                                    {({ getScreenshot }) => (
                                      <>
                                        <Button
                                          className="me-2"
                                          variant="primary"
                                          onClick={() => {
                                            const imageSrc = getScreenshot();
                                            hanldeWebCam(
                                              imageSrc,
                                              i,
                                              setauthService,
                                              authservice
                                            );
                                          }}
                                        >
                                          Capture
                                        </Button>
                                        {/* <Button
                                          className="me-2"
                                          variant="primary"
                                          onClick={() => {
                                            handleClick1(i);
                                          }}
                                        >
                                          Switch Camera
                                        </Button> */}
                                      </>
                                    )}
                                  </Webcam>
                                )}

                                {v.file && (
                                  <>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <img
                                        src={URL.createObjectURL(v.file)}
                                        key={i}
                                        alt="screenshots"
                                        width="200px"
                                      />

                                      <Button
                                        style={{
                                          position: "absolute",
                                          top: "-10px",
                                          right: "-15px",
                                          cursor: "pointer",
                                          zIndex: "1",
                                          fontSize: "16px",
                                          padding: "0px",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                        // disabled={popsinger.length === 1}
                                        className="me-1"
                                        variant="danger"
                                        onClick={() => handleRemoveClick1(i)}
                                      >
                                        X{/* <DeleteIcon/> */}
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : v.type === "media" ? (
                              <ImageUploader
                                onChange={(a) => FileUpload2(a, i)}
                                file={v.file}
                                aspect={1080 / 1440}
                              />
                            ) : null}
                          </div>
                          <div className="d-flex"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
              <hr />
              <div className="row">
                {popsinger.map((e, i, arr) => (
                  <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                    <label
                      className="text-white"
                      style={{ fontSize: "medium" }}
                    >
                      POP SIGNER PICTURE {i + 1}{" "}
                    </label>
                    <div className="d-flex align-items-center mb-3">
                      <div className="form-group text-center">
                        {!e.type ? (
                          <>
                            <Button
                              className="me-2 mb-1"
                              variant="primary"
                              value={"camera"}
                              onClick={(a) =>
                                handleUploadType(
                                  a,
                                  i,
                                  setPopsinger,
                                  popsinger,
                                  "camera"
                                )
                              }
                            >
                              camera
                            </Button>
                            <Button
                              className="me-2"
                              variant="primary"
                              value={"media"}
                              onClick={(a) =>
                                handleUploadType(
                                  a,
                                  i,
                                  setPopsinger,
                                  popsinger,
                                  "media"
                                )
                              }
                            >
                              media
                            </Button>
                          </>
                        ) : null}

                        {e.type === "camera" ? (
                          <>
                            {!e.file && (
                              <Webcam
                                audio={false}
                                // height={720}
                                screenshotFormat="image/jpeg"
                                width={150}
                                mirrored={
                                  facingMode[i]?.facingMode === "environment"
                                    ? false
                                    : true
                                }
                                videoConstraints={facingMode[i]}
                              >
                                {({ getScreenshot }) => (
                                  <>
                                    <Button
                                      className="me-2"
                                      variant="primary"
                                      onClick={() => {
                                        const imageSrc = getScreenshot();
                                        hanldeWebCam(
                                          imageSrc,
                                          i,
                                          setPopsinger,
                                          popsinger
                                        );
                                      }}
                                    >
                                      Capture
                                    </Button>
                                    {/* <Button
                                      className="me-2"
                                      variant="primary"
                                      onClick={() => {
                                        handleClick(i);
                                      }}
                                    >
                                      Switch Camera
                                    </Button> */}
                                  </>
                                )}
                              </Webcam>
                            )}

                            {e.file && (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={URL.createObjectURL(e.file)}
                                    key={i}
                                    alt="screenshots"
                                    width="150px"
                                  />

                                  <Button
                                    style={{
                                      position: "absolute",
                                      top: "-10px",
                                      right: "-15px",
                                      cursor: "pointer",
                                      zIndex: "1",
                                      fontSize: "16px",
                                      padding: "0px",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                    // disabled={popsinger.length === 1}
                                    className="me-1"
                                    variant="danger"
                                    onClick={() =>
                                      handleRemovePopSignerImage(i)
                                    }
                                  >
                                    X{/* <DeleteIcon/> */}
                                  </Button>
                                </div>
                              </>
                            )}
                          </>
                        ) : e.type === "media" ? (
                          <ImageUploader
                            onChange={(a) => FileUpload(a, i)}
                            file={e.file}
                            aspect={1080 / 1440}
                          />
                        ) : null}
                      </div>
                      <div className="d-flex">
                        <Button
                          disabled={popsinger.length === 1}
                          className="me-2"
                          variant="primary"
                          onClick={() => handleRemoveClick(i)}
                        >
                          -
                        </Button>
                        <Button
                          disabled={popsinger.length === 15}
                          hidden={i !== arr.length - 1}
                          className="me-2"
                          variant="primary"
                          onClick={() => Additem()}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                FUNKO POP DETAILS
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        FUNKO POP SERIES
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Pop Series"
                        onChange={(e) =>
                          handleInputChange(e, setFunkopopseries)
                        }
                        value={funkopopseries}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        FUNKO POP NUMBER
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Funko Pop Number"
                        onChange={(e) =>
                          handleInputChange(e, setFunkopopnumber)
                        }
                        value={funkopopnumber}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        FUNKO POP NAME
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Funko Pop Name"
                        onChange={(e) => handleInputChange(e, setFunkopopname)}
                        value={funkopopname}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        UPC NUMBER
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Upc Number"
                        onChange={(e) => handleInputChange(e, setUpcnumber)}
                        value={upcnumber}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        BOX DATE CODE
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Box Date Code"
                        onChange={(e) => handleInputChange(e, setBoxdatecode)}
                        value={boxdatecode}
                        maxLength={12}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                AUTHENTICATION DETAILS
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row align-items-end">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        STATUS
                      </label>
                      <select
                        onChange={(e) => handleInputChange(e, setStatus)}
                        value={status}
                        className="form-control form-control-select"
                      >
                        <option value={"authenticated"}>AUTHENTICATED</option>
                        <option value={"pending"}>PENDING</option>
                        <option value="lost">LOST</option>
                        <option value={"stolen"}>STOLEN</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        LOCATION SIGNED
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Location Signed"
                        onChange={(e) =>
                          handleInputChange(e, setLocationsigned)
                        }
                        value={locationsigned}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        DATE SIGNED
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setDatesigned)}
                        value={datesigned}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        SIGNED AFFIDAVIT
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Signed Affidavit"
                        onChange={(e) => handleInputChange(e, setSignaffidavit)}
                        value={signaffidavit}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        VIDEO OR CLIP
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setVideoorclip(e.target.files[0])}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        AUTHENTICATION WITNESS
                      </label>
                      <select
                        onChange={(e) =>
                          handleInputChange(e, setAuthenticationwitness)
                        }
                        value={authenticationwitness}
                        className=" form-control form-control-select"
                      >
                        <option value="">select agent</option>
                        {loginUser.role.name === "admin" && (
                          <option value={loginUser.role.id}>
                            {loginUser.role.name}
                          </option>
                        )}
                        {agents.map((e, i) => (
                          <option value={e.id} key={i}>
                            {e.first_name + " " + e.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        GPS META DATA
                      </label>
                      <select
                        onChange={(e) => handleInputChange(e, setGpsdata)}
                        value={gpsdata}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="verified">VERIFIED</option>
                        <option value={"n/a"}>N/A</option>
                      </select>
                    </div>
                    <div className="col-md-3 text-center mb-3 ">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        AUTHENTICATION GUARANTEE
                      </label>
                      <select
                        onChange={(e) =>
                          handleInputChange(e, setAuthenticationGuarentee)
                        }
                        value={authenticationguarentee}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="options">
                          Please select one option
                        </option>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                      </select>
                    </div>
                    {/* <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                      <select
                        onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                        defaultValue={"option"}
                        id="inputState"
                        className="form-control form-control-select"
                        value={authenticationguarentee}
                      >
                        <option value="options" >
                          Please select one option
                        </option>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                      </select>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                SIGNATURE DETAILS
              </h4>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group mb-3 col-md-12 text-center">
                        <label style={{ fontSize: "larger", color: "white" }}>
                          SIGNATURE TYPE
                        </label>
                        <select
                          onChange={(e) =>
                            handleInputChange(e, setSignaturetype)
                          }
                          value={signaturetype}
                          id="inputState"
                          className=" form-control form-control-select"
                        >
                          <option value="options">
                            Please select one option
                          </option>
                          <option value={"Single"}>Single</option>
                          <option value={"Multiple"}>Multiple</option>
                        </select>
                      </div>
                      {/* <div className="form-group mb-3 col-md-12 text-center">
                        <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                        <select

                          onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                          defaultValue={"option"}
                          id="inputState"
                          className="form-control"
                          value={authenticationguarentee}
                        >
                          <option value="option" disabled>
                            Please select one option
                          </option>
                          <option value={"yes"}>Yes</option>
                          <option value={"no"}>No</option>

                        </select>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-7 text-center mb-3 mt-4">
                <div className="form-group mb-3 col-md-12 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>
                    SIGNER / SIGNERS
                  </label>
                  <CreatableSelect
                    isMulti
                    styles={colourStyles}
                    options={signer}
                    onChange={(e) => setSigner(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                POP FAX
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row align-items-end">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        REGISTERED OWNER
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Registrated Owner"
                        onChange={(e) => handleInputChange(e, setOwner)}
                        value={owner}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        PURCHASE DATE
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Purchase Date"
                        onChange={(e) => handleInputChange(e, setPurdate)}
                        value={purdate}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        PURCHASE PRICE
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Purchase Price"
                        onChange={(e) => handleInputChange(e, setPurprice)}
                        value={purprice}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center mt-4">
                      <Button className="me-2" variant="primary">
                        UPDATE
                      </Button>
                    </div>
                    <div className="form-group mb-3 col-md-12  mt-4">
                      <Button
                        className="me-2"
                        variant="primary"
                        style={{ fontSize: "x-Large" }}
                      >
                        TRANSFER TO PREVIOUS OWNERSHIP HISTORY
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                ENHANCED DETAILS
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        ENCAPSULATED
                      </label>
                      <select
                        onChange={(e) => handleInputChange(e, setEncapsulation)}
                        value={encapsulation}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="options">
                          Please select one option
                        </option>
                        <option value={"Yes"}>yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        GRADED
                      </label>
                      <select
                        onChange={(e) => handleInputChange(e, setGraded)}
                        value={graded}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="options">
                          Please select one option
                        </option>
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        GRADE NUMBER
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Grade Number"
                        onChange={(e) => handleInputChange(e, setGradenum)}
                        value={gradenum}
                        min={0}
                        max={10}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center ">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        GRADING COMPANY
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Grading Company"
                        onChange={(e) => handleInputChange(e, setGradecomp)}
                        value={gradecomp}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center ">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        GRADING REPORT LINK
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Gradeing Report Link"
                        onChange={(e) => handleInputChange(e, setGradingpic)}
                        value={gradingpic}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>
                NOTES
              </h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6 text-center">
                      {/* <label style={{ fontSize: "larger", color: "white" }}>Notes</label> */}
                      <textarea
                        className="textarea"
                        placeholder="Write a note of 200 letters"
                        value={notes}
                        rows={12}
                        maxLength={200}
                        style={{
                          width: "100%",
                          padding: "1rem",
                          borderRadius: "1rem",
                        }}
                        onChange={(e) => handleInputChange(e, setNotes)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-5 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>
                        Limit
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setLimit)}
                        value={limit}
                        placeholder="Enter limit"
                        min={1}
                        max={100}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <Button
              className="me-2"
              variant="primary"
              onClick={() => navigate("/PopCards")}
            >
              Back
            </Button>
            <Button
              className="me-2"
              variant="primary"
              onClick={() => AddPopCard()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddPopcard;
