import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import ThemeContext from "./context/ThemeContext";
import { ToastContainer } from "react-toastify";
import 'react-overlay-loader/styles.css';
import 'react-toastify/dist/ReactToastify.css';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";

ReactDOM.render(
    <React.StrictMode>
        <ToastContainer />
        <Provider store={store}>
            <SimpleReactLightbox>
                <BrowserRouter>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
