/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { FaUsers } from 'react-icons/fa'
import { RiAdminFill } from 'react-icons/ri'
import { AiOutlineFileText } from 'react-icons/ai'

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector('.heart');
    //       function heartBlast() {
    //           return handleheartBlast.classList.toggle("heart-blast");
    //       }
    //       handleheartBlast.addEventListener('click', heartBlast);

  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // pathId
  let pathId = window.location.pathname;
  pathId = pathId.split("/");
  pathId = pathId[pathId.length - 2];

  /// Active menu
  let deshBoard = [
    "",
    "dashboard-dark",
    "guest-list",
    "guest-detail",
    "concierge",
    "room-list",
    "reviews",
    "task",
  ];


  let PopCards = ["PopCards", "Clone", "AddPopcard", "EditPopcard"];
  let Members = ["members", "AddMember"];
  let Agents = ["agents", "add-agent"];
  let Logs = ["logs"];

  const Role = JSON.parse(localStorage.getItem("user"))
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {Role.role.name === "admin" ?
            <>
              <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                <Link to="/" className="ai-icon" >
                  <i className="flaticon-025-dashboard"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>

              <li className={`${(PopCards.includes(path) || PopCards.includes(pathId)) ? "mm-active" : ""}`}>
                <Link to="PopCards" className="ai-icon" >
                  <i className="flaticon-013-checkmark"></i>
                  <span className="nav-text">Pop Card</span>
                </Link>
              </li>
              <li className={`${Members.includes(path) ? "mm-active" : ""}`}>
                <Link className=" ai-icon" to="members" >
                  <i className=""><FaUsers /></i>
                  <span className="nav-text ">Members</span>
                </Link>

              </li>

              <li className={`${Agents.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="agents" >
                  <i className=""><RiAdminFill /></i>
                  <span className="nav-text ">Agents</span>
                </Link>
              </li>

              <li className={`${Logs.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="logs" >
                  <i className=""><AiOutlineFileText /></i>
                  <span className="nav-text ">Logs</span>
                </Link>
              </li>
            </> :
            (Role.role.name === "agent" ?
              <>
                <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/" className="ai-icon" >
                    <i className="flaticon-025-dashboard"></i>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </li>

                <li className={`${PopCards.includes(path) ? "mm-active" : ""}`}>
                  <Link to="PopCards" className="ai-icon" >
                    <i className="flaticon-013-checkmark"></i>
                    <span className="nav-text">Pop Card</span>
                  </Link>
                </li>
              </>
              :
              (Role.role.name === "customer" ?
                <>
                  <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/" className="ai-icon" >
                      <i className="flaticon-025-dashboard"></i>
                      <span className="nav-text">Dashboard</span>
                    </Link>
                  </li>

                  <li className={`${PopCards.includes(path) ? "mm-active" : ""}`}>
                    <Link to="PopCards" className="ai-icon" >
                      <i className="flaticon-013-checkmark"></i>
                      <span className="nav-text">Pop Card</span>
                    </Link>
                  </li>
                </>
                :
                ""
              ))}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
