// import axios from 'axios';
// import { store } from '../store/store';

// const axiosInstance = axios.create({
//     baseURL: `https://react-course-b798e-default-rtdb.firebaseio.com/`,
// });

// axiosInstance.interceptors.request.use((config) => {
//     const state = store.getState();
//     const token = state.auth.auth.idToken;
//     config.params = config.params || {};
//     config.params['auth'] = token;
//     return config;
// });

// export default axiosInstance;

import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_CLIENT,
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("error",error)
        const Data = error?.response?.data;
        const errors = Object.keys(Data?.errors);
        if (errors) {
            errors.map((e) => toast.error(Data.errors[e][0]));
        } else {
            if (Data) {
                toast.error(Data?.message);
            } else {
                toast.error(error.message);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
