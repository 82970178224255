import { ArrowDownward } from "@material-ui/icons";
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from "@material-ui/icons/Clear";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Popover } from "antd";
import { Loader } from "react-overlay-loader";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const BasicDatatable = ({ data, setData, handleSelect, isLoader }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const Delete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        axiosInstance.delete(`/popcard/${id}`).then((response) => {
          setLoader(false)
          const Data = response.data;
          if (Data.status) {
            setData(data.filter(data => data.id !== id))
            toast.success(Data.message)
          } else {
            toast.warn(Data.message)
          }
        })
      }
    });
  }

  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="table-responsive">
        <div id="job_data" className="dataTables_wrapper">
          <div className="row">
            <div className="col-md-12 item">
              <MaterialTable
                isLoading={isLoader || false}
                title=""
                icons={{
                  Check: Check,
                  DetailPanel: ChevronRight,
                  // Delete: DeleteOutline,
                  Export: SaveAlt,
                  Filter: FilterList,
                  FirstPage: FirstPage,
                  LastPage: LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  Search: Search,
                  ThirdStateCheck: Remove,
                  Add: Add,
                  SortArrow: ArrowDownward,
                  Clear: Clear,
                  Edit: Edit,
                  ViewColumn: ViewColumn,
                  ResetSearch: Clear
                }}

                columns={[
                  { title: '', render: item => handleSelect && <input type="checkbox" onChange={(e) => handleSelect(e, item)} /> },
                  { title: 'Sticker Number  ', field: 'stickerNumber' },
                  { title: 'POP Picture', field: 'poppicture', render: item => item.poppicture && <img src={item.poppicture[0]} alt="" width={100} /> },
                  { title: 'POP Card name', field: 'popcard', headerStyle: { width: "100px" } },
                  { title: 'POP Card Color', field: 'popcardColor' },
                  { title: 'For Pass', field: 'forPass' },
                  { title: 'Status', field: 'status' },
                  { title: 'Authentication Services ', field: 'authenticationService' },
                  {
                    title: 'Signer ', field: 'signer', render: item => <Popover content={item.signer.map((e) => <p className="mb-0">{e}</p>)} title={'Signers'}>
                      Show
                    </Popover>
                  },
                  { title: 'Signature Type', field: 'signatureType' },
                  { title: 'Funko Pop Name', field: 'funkoPopName' },
                  { title: 'Funko Pop Number', field: 'funkoPopNumber' },
                  { title: 'Funko Pop Series', field: 'funkoPopSeries' },
                  { title: 'Authentication Witness', field: 'authenticationWitness', render: item => item.authenticationWitness.first_name + ' ' + item.authenticationWitness.last_name },
                  { title: 'Sign Affidavit', field: 'signAffidavit' },
                  { title: 'Date Signed ', field: 'dateSigned' },
                  { title: 'Encapsulated ', field: 'encapsulated' },
                  { title: 'Gps MetaData ', field: 'gpsMetaData' },
                  { title: 'GradeNumber ', field: 'gradeNumber' },
                  { title: 'Graded ', field: 'graded' },
                  { title: 'Grading Company ', field: 'gradingCompany' },
                  { title: 'Grading Report Link ', field: 'gradingReportLink' },
                  { title: 'Location Signed ', field: 'locationSigned' },
                  { title: 'Authentication Guarentee', field: 'authenticationGuarentee' },
                  { title: 'Video Or Clip', field: 'videoOrClip', render: item => item.videoOrClip && <a href={item.videoOrClip} target="_blank" rel="no-refferer noreferrer">Click here</a> },
                  { title: 'POP Picture Border Color', field: 'popPictureBordorColor' },
                  { title: 'POP Signer', field: 'popSigner', render: item => item.popSigner && <img src={item.popSigner[0]?.pop_signer} alt="" width={100} /> },
                  { title: 'POP Signer Border Color', field: 'popSignerBordorColor' },
                ]}
                data={data}
                actions={[
                  {
                    icon: Edit,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => {
                      navigate(`/EditPopcard/${rowData.id}`, { state: rowData })
                    }
                  },
                  {
                    icon: DeleteIcon,
                    tooltip: 'Delete',
                    onClick: (event, rowData) => {
                      Delete(rowData.id);
                    }
                  },
                ]}
                style={{
                  backgroundColor: '#1d2333',
                  color: '#fff'
                }}

                options={{
                  headerStyle: {
                    backgroundColor: '#1d2333',
                    color: '#fff',
                    zIndex: 0
                  },
                  searchFieldStyle: {
                    // backgroundColor: '#1d2333',
                    color: '#fff'
                  },
                  paging: {
                    // backgroundColor: '#1d2333',
                    color: '#fff'
                  },
                  actionsCellStyle: {
                    color: "#fff"

                  },
                  actionsColumnIndex: -1,
                  pageSize: 10,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDatatable;
