import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader";
import axiosInstance from "../../../services/AxiosInstance";
import BasicDatatable from "./BasicDatatable";

const PopCards = () => {
  const navigate = useNavigate();
  const ExcelFile = useRef(null);
  const [data, setData] = useState([]);
  const [dataloader, setDataLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState([]);
  const loginUser = JSON.parse(localStorage.getItem("user"));
  const [modal, setModal] = useState(false);
  const [files, setFiles] = useState(null);

  useEffect(() => {
    GetData();
  }, [])

  const GetData = () => {
    setDataLoader(true)
    axiosInstance.get('popcard').then((response) => {
      setDataLoader(false)
      if (response.status === 200) {
        setData(response.data.popcard)
      } else {
        if (response.data.message === "") {
          console.log("else check msg")
        }
      }
    }).catch(error => {
      setDataLoader(false)
      console.log("error", error)
    });
  }

  const handleImport = () => {
    ExcelFile.current.click();
  }

  const Import = (e) => {
    setLoader(true)
    const form = new FormData();
    form.append('file', e.target.files[0]);
    axiosInstance.post('import', form).then((response) => {
      setLoader(false)
      const Data = response.data;
      if (Data.status) {
        GetData();
        toast.success(Data.message)
      } else {
        toast.warn(Data.message)
      }
    }).catch(error => {
      console.log("error")
    });
  }

  const Export = () => {
    if (selected.length) {
      setLoader(true)
      const form = new FormData();
      selected.map((e) => form.append('id[]', e))
      axiosInstance.post('export', form).then((response) => {
        setLoader(false);
        const Data = response.data;
        if (Data.status) {
          ExportDecodeExcelFile(Data.base64, 'popcard');
          toast.success(Data.message)
        } else {
          toast.warn(Data.message)
        }
      })
    } else {
      toast.info('select atleast one record')
    }
  }


  // const ExportDB = () => {
  //   setLoader(true)
  //   axiosInstance.get('all/export').then((response) => {
  //     setLoader(false);
  //     const Data = response.data;
  //     if (Data.status) {
  //       ExportDecodeExcelFile(Data.base64, 'db');
  //       toast.success(Data.message)
  //     } else {
  //       toast.warn(Data.message)
  //     }
  //   })
  // }

  const ExportDecodeExcelFile = (url, filename) => {
    var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${url}`;
    var a = document.createElement('a');
    a.href = mediaType;
    a.setAttribute('download', `${filename}.xlsx`);
    document.body.appendChild(a);
    a.click();
  }

  const DownloadSample = (url) => {
    var mediaType = `/popcard_sample.xlsx`;
    var a = document.createElement('a');
    a.href = mediaType;
    a.setAttribute('download', 'popcard_sample.xlsx');
    document.body.appendChild(a);
    a.click();
  }

  const handleSelect = (e, item) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, item.id])
    } else {
      setSelected(selected.filter(a => a !== item.id))
    }
  }

  const handleModal = () => {
    if (selected.length) {
      setModal(!modal);
    } else {
      toast.info('select atleast one record')
    }
  }

  const uploadVideo = () => {
    if (files !== null) {
      setLoader(true)
      const form = new FormData();
      selected.map((e) => form.append('id[]', e))
      form.append('videoOrClip', files)
      axiosInstance.post('video/upload', form).then((response) => {
        setLoader(false);
        const Data = response.data;
        if (Data.status) {

          toast.success(Data.message)
        } else {
          toast.warn(Data.message)
        }
      })
    } else {
      toast.info('please select file')
    }
  }


  return (
    <Fragment>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header d-block">
              <div className="row justify-content-around g-2">
                {loginUser.role.name === "admin" ?
                  <>
                    <div className="col-md-6">
                      <Button className="me-2" variant="primary" onClick={handleImport}>
                        import
                      </Button>
                      <input type="file" ref={ExcelFile} onChange={(e) => Import(e)} hidden />
                      <Button className="me-2" variant="primary" onClick={Export}>
                        Export
                      </Button>
                      <Link to="/Clone">
                        <Button className="me-2" variant="primary">Clone</Button>
                      </Link>
                      <Button className="me-2" variant="primary" onClick={handleModal}>
                        Upload Video
                      </Button>
                    </div>
                    <div className="col-md-6 text-end">
                      <Button className="me-2" variant="primary" onClick={DownloadSample}>
                        Download
                      </Button>
                      <Button onClick={() => navigate("/AddPopcard")} className="me-2" variant="primary">
                        Add
                      </Button>
                    </div>
                  </>
                  :
                  (loginUser.role.name === "agent" ?
                    <div className="col-md-12 text-end">
                      <Button onClick={() => navigate("/AddPopcard")} className="me-2" variant="primary">
                        Add
                      </Button>
                      <Link to="/Clone"><Button className="me-2" variant="primary" >
                        Clone
                      </Button></Link>
                    </div>
                    :
                    (loginUser.role.name === "customer" ?
                      <>
                        <div className="col-md-12 text-end">
                          <Button onClick={() => navigate("/AddPopcard")} className="me-2 mt-3" variant="primary">
                            Add
                          </Button>
                          <Link to="/Clone"><Button className="me-2 mt-3" variant="primary" >
                            Clone
                          </Button></Link>

                        </div>
                      </>
                      :
                      null
                    ))}
              </div>
            </div>
            <div className="card-body">
              <BasicDatatable data={data} setData={setData} handleSelect={handleSelect} isLoader={dataloader} />
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" show={modal}>
        <Modal.Header>
          <Modal.Title>Upload Video Or Clip</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="input-group">
            <div className="from-file">
              <input type="file" className="form-file-input form-control" onChange={(e) => setFiles(e.target.files[0])} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => setModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={uploadVideo}>Upload Video</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PopCards;
