import { ArrowDownward } from "@material-ui/icons";
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from "@material-ui/icons/Clear";
// import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Loader } from "react-overlay-loader";
import axiosInstance from "../../../services/AxiosInstance";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Members = () => {
  const navigate = useNavigate();
  const [data, setData] = useState()
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    GetData()
  }, [])


  const GetData = () => {
    setLoader(true);
    axiosInstance.get('customer',).then((response) => {
      setLoader(false)
      if (response.status === 200) {
        setData(response.data.data);
      } else {
        if (response.data.message === "") {
          console.log("else check msg")
        }
      }
    }).catch(error => {
      console.log("error")
    });
  }

  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="col-12">
        <div className="card">
          <div className="card-header justify-content-between">
            <h4 className="card-title mb-0" style={{ fontSize: "20px" }}>MEMBERS</h4>
            <Button variant="primary" style={{ width: '10rem', height: 'fit-content' }} onClick={() => navigate('/AddMember')}>
              Add
            </Button>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div id="job_data" className="dataTables_wrapper">
                <div className="row">
                  <div className="col-md-12 item">
                    <MaterialTable
                      title=""
                      icons={{
                        Check: Check,
                        DetailPanel: ChevronRight,
                        // Delete: DeleteOutline,
                        Export: SaveAlt,
                        Filter: FilterList,
                        FirstPage: FirstPage,
                        LastPage: LastPage,
                        NextPage: ChevronRight,
                        PreviousPage: ChevronLeft,
                        Search: Search,
                        ThirdStateCheck: Remove,
                        Add: Add,
                        SortArrow: ArrowDownward,
                        Clear: Clear,
                        Edit: Edit,
                        ViewColumn: ViewColumn,
                        ResetSearch: Clear
                      }}

                      columns={[
                        { title: 'First Name ', field: 'first_name', width: "100%" },
                        { title: 'Last Name', field: 'last_name', width: "100%" },
                        { title: 'Email ', field: 'email', width: "100%" },
                        { title: 'Role ', field: 'role', render: item => item.role.name },
                      ]}
                      data={data}
                      // actions={[
                      //   {
                      //     icon: DeleteIcon,
                      //     tooltip: 'Delete User',
                      //     onClick: (event, rowData) => {
                      //       console.log("delete")
                      //     }

                      //   },
                      //   rowData => ({

                      //     icon: Edit,
                      //     tooltip: 'Edit Icon',
                      //     onClick: (event, rowData) => {

                      //       console.log("edit icon")


                      //     }
                      //   })

                      // ]}
                      style={{
                        backgroundColor: '#1d2333',
                        color: '#fff'
                      }}

                      options={{
                        headerStyle: {
                          backgroundColor: '#1d2333',
                          color: '#fff',
                          zIndex: 0
                        },
                        searchFieldStyle: {
                          // backgroundColor: '#1d2333',
                          color: '#fff'
                        },
                        paging: {
                          // backgroundColor: '#1d2333',
                          color: '#fff'
                        },
                        actionsCellStyle: {
                          color: "#fff"

                        },
                        actionsColumnIndex: -1,
                        pageSize: 10,
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Members;
