import { ArrowDownward } from "@material-ui/icons";
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from "@material-ui/icons/Clear";
// import DeleteIcon from '@material-ui/icons/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../services/AxiosInstance";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Switch } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { Loader } from "react-overlay-loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Agent = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    GetData()
  }, [])

  const GetData = () => {
    setLoader(true)
    axiosInstance.get('agent').then((response) => {
      setLoader(false);
      if (response.status === 200) {
        setData(response.data.data);
      } else {
        if (response.data.message === "") {
          console.log("else check msg")
        }
      }
    }).catch(error => {
      setLoader(false);
      console.log("error")
    });

  }

  const Delete = (id) => {
    setLoader(true);
    axiosInstance.delete(`/agent/${id}`).then((response) => {
      setLoader(false)
      const Data = response.data;
      if (Data.status) {
        setData(data.filter(data => data.id !== id));
        toast.success(Data.message);
      } else {
        toast.warn(Data.message);
      }
    })
  }

  const handleRoleChange = (id) => {
    axiosInstance.get(`/convert/role/${id}`).then((response) => {
      setLoader(false);
      const Data = response.data;
      if (Data.status) {
        toast.success(Data.message);
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        window.location.reload();
      } else {
        toast.warn(Data.message);
      }
    })
  }

  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="col-12">
        <div className="card">
          <div className="card-header justify-content-between">
            <h4 className="card-title mb-0" style={{ fontSize: "20px" }}>AGENTS</h4>
            <Button variant="primary" style={{ width: '10rem', height: 'fit-content' }} onClick={() => navigate('/add-agent')}>
              Add
            </Button>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div id="job_data" className="dataTables_wrapper">
                <div className="row">
                  <div className="col-md-12 item">
                    <MaterialTable
                      title=""
                      icons={{
                        Check: Check,
                        DetailPanel: ChevronRight,
                        // Delete: DeleteOutline,
                        Export: SaveAlt,
                        Filter: FilterList,
                        FirstPage: FirstPage,
                        LastPage: LastPage,
                        NextPage: ChevronRight,
                        PreviousPage: ChevronLeft,
                        Search: Search,
                        ThirdStateCheck: Remove,
                        Add: Add,
                        SortArrow: ArrowDownward,
                        Clear: Clear,
                        Edit: Edit,
                        ViewColumn: ViewColumn,
                        ResetSearch: Clear
                      }}

                      columns={[
                        { title: 'First Name ', field: 'first_name', width: "100%" },
                        { title: 'Last Name', field: 'last_name', width: "100%" },
                        { title: 'Email ', field: 'email', width: "100%" },
                        { title: 'Witness ', field: 'witness', width: "100%" },
                        { title: 'Role ', field: 'role', render: item => item.role.name },
                        {
                          title: 'Change Permissions',
                          field: 'role',
                          render: item => <Switch
                            checked={item.role.name === 'admin' ? true : false}
                            onChange={() => handleRoleChange(item.id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        },
                      ]}
                      data={data}
                      actions={[
                        {
                          icon: Edit,
                          tooltip: 'Edit',
                          onClick: (event, rowData) => {
                            navigate(`/edit-agent/${rowData.id}`, { state: rowData })
                          }
                        },
                        {
                          icon: DeleteIcon,
                          tooltip: 'Delete',
                          onClick: (event, rowData) => {
                            Delete(rowData.id);
                          }
                        },
                      ]}
                      style={{
                        backgroundColor: '#1d2333',
                        color: '#fff'
                      }}

                      options={{
                        headerStyle: {
                          backgroundColor: '#1d2333',
                          color: '#fff',
                          zIndex: 0
                        },
                        searchFieldStyle: {
                          // backgroundColor: '#1d2333',
                          color: '#fff'
                        },
                        paging: {
                          // backgroundColor: '#1d2333',
                          color: '#fff'
                        },
                        actionsCellStyle: {
                          color: "#fff"

                        },
                        actionsColumnIndex: -1,
                        pageSize: 10,
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Agent;
