import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { Loader } from "react-overlay-loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../../../services/AxiosInstance";

const AddAgent = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Witness, setWitness] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [Role,] = useState("agent");

  const add = () => {
    setLoader(true)
    const formData = new FormData()
    formData.append("first_name", FirstName)
    formData.append("last_name", LastName)
    formData.append("email", Email)
    formData.append("witness", Witness)
    formData.append("password", Password)
    formData.append("password_confirmation", ConfirmPassword)
    formData.append("role", Role)

    axiosInstance.post('agent', formData).then((response) => {
      const Data = response.data;
      setLoader(false);
      if (response.status === 200) {
        toast.success(Data.message);
        navigate('/agents');
      } else {
        toast.error(Data.message);
      }
    }).catch(error => {
      setLoader(false);
      toast.error(error);
    });
  }

  const handleInputChange = (e, func) => {
    func(e.target.value)
  }

  return (
    <Fragment>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "20px" }}>ADD AGENT</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        onChange={(e) => handleInputChange(e, setFirstName)}
                        value={FirstName}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last Name"
                        onChange={(e) => handleInputChange(e, setLastName)}
                        value={LastName}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        onChange={(e) => handleInputChange(e, setEmail)}
                        value={Email}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>Witness</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Witness"
                        onChange={(e) => handleInputChange(e, setWitness)}
                        value={Witness}
                      />
                    </div>
                    {/* <div className="form-group mb-3 col-md-6">
                          <label style={{ fontSize: "larger", color: "white" }}>Role</label>
                          <input
                            type="text"
                            className="form-control"
                            value={Role}
                            disabled
                          />
                        </div> */}
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                        onChange={(e) => handleInputChange(e, setPassword)}
                        value={Password}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label style={{ fontSize: "larger", color: "white" }}>Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Confirm Password"
                        onChange={(e) => handleInputChange(e, setConfirmPassword)}
                        value={ConfirmPassword}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-between">
                  <Button className="me-2" variant="primary" onClick={() => navigate("/agents")}>
                    Back
                  </Button>
                  <Button className="me-2" variant="primary" onClick={() => add()}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAgent;
