import React, { useState } from "react";
import { Button } from "react-bootstrap";
// import CreatableSelect from 'react-select/creatable';
import { BsSearch } from "react-icons/bs";
import { Loader } from "react-overlay-loader";
import PopSearch from "../../PopSearch";
import SingleClone from "../../SingleClone";
import MultipleClone from "../../MultipleClone";

const Clone = () => {
  const [loader,] = useState(false);

  // const colourStyles = {
  //   control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  //   multiValue: (styles, { data }) => {
  //     const color = '#e31990';
  //     return {
  //       ...styles,
  //       backgroundColor: '#e31990',
  //     };
  //   },
  //   multiValueLabel: (styles, { data }) => ({
  //     ...styles,
  //     color: 'white',
  //   }),
  //   multiValueRemove: (styles, { data }) => ({
  //     ...styles,
  //     color: 'white',
  //     ':hover': {
  //       backgroundColor: '#e31990',
  //       color: 'white',
  //     },
  //   }),
  // };


  return (
    <>
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="row">
        {/* Pop Search */}
        <div className="col-xl-12 col-lg-12">
          <PopSearch />
        </div>

        {/* Single Clone */}
        <div className="col-xl-6 col-lg-6">
          <SingleClone />
        </div>

        {/* Clone Range */}
        <div className="col-xl-6 col-lg-6">
          <MultipleClone />
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>ANOTHER TAB</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-3 col-md-3 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>STOCK IMAGE REPOSITORY</label>
                  <input
                    type="text"
                    placeholder="Enter Stock Image Repository"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-1 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>&nbsp;</label>
                  <Button variant="primary"><BsSearch size={25} /></Button>
                </div>
                <div className="form-group col-md-3 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>ADD</label>
                  <br />
                  <Button variant="primary" className="">  UPLOAD IMAGE</Button>
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-3 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>SINGLE REPOSITORY</label>
                  <input
                    type="text"
                    placeholder="Enter Single Repository"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-1 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>&nbsp;</label>
                  <Button variant="primary"><BsSearch size={25} /></Button>
                </div>
                <div className="form-group col-md-3 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>ADD</label>
                  <br />
                  <Button variant="primary" className="">  UPLOAD IMAGE</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clone;
