import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'
import { Loader } from 'react-overlay-loader'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axiosInstance from '../../../services/AxiosInstance'

export default function MultipleClone() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState("");
    const [multipleCloneNumber, setMultipleCloneNumber] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const onMultipleClone = () => {
        setLoader(true)
        const form = new FormData()
        form.append("stickerNumber", multipleCloneNumber)
        form.append("start", start)
        form.append("end", end)

        axiosInstance.post('multiple/clone', form).then((response) => {
            setLoader(false)
            const Data = response.data.status
            if (Data) {
                toast.success(response.data.message)
                navigate("/PopCards")
            } else {
                toast.error(response.data.message)
            }
        }).catch(error => {
            toast.error(error)
            setLoader(false)
        });
    }
    return (
        <>
            {loader === true ? <Loader fullPage loading /> : null}
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title" style={{ fontSize: "30px" }}>CLONE BULKING</h4>
                </div>
                <div className="card-body">
                    <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="form-group col-md-4 text-center">
                                    <label style={{ fontSize: "larger", color: "white" }}>CLONE NUMBER</label>
                                    <ReactInputMask
                                        mask="a-999999"
                                        type="text"
                                        className="form-control"
                                        placeholder="S - 100250"
                                        value={multipleCloneNumber}
                                        onChange={(e) => setMultipleCloneNumber(e.target.value)}
                                    />
                                </div>

                                <div className="form-group col-md-4 text-center">
                                    <label style={{ fontSize: "larger", color: "white" }}>START</label>
                                    <ReactInputMask
                                        mask="a-999999"
                                        type="text"
                                        className="form-control"
                                        placeholder="S - 100250"
                                        value={start}
                                        onChange={(e) => setStart(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4 text-center">
                                    <label style={{ fontSize: "larger", color: "white" }}>FINISH</label>
                                    <ReactInputMask
                                        mask="a-999999"
                                        type="text"
                                        className="form-control"
                                        placeholder="S - 100250"
                                        value={end}
                                        onChange={(e) => setEnd(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label style={{ fontSize: "larger", color: "white" }}>&nbsp;</label>
                                    <Button variant="primary" className="w-100" disabled={end === ""} onClick={() => onMultipleClone()}>  CLONE RANGE</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
