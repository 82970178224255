import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import InputMask from 'react-input-mask';
import { Loader } from "react-overlay-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import { toast } from "react-toastify";
import axiosInstance from "../../../../services/AxiosInstance";
import ImageUploader from "../../ImgCropUploader";

const EditPopcard = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const [loginUser] = useState(JSON.parse(localStorage.getItem('user')))
  const params = useParams();
  const [agents, setAgents] = useState([])
  const [authservice, setauthService] = useState(state.poppicture)
  const [popsinger, setPopsinger] = useState(state.popSigner)
  const [loader, setLoader] = useState(false);
  // ADD POPCARD STATES 
  const [stickernumber, setStickernumber] = useState(state.stickerNumber)
  const [popcardcolor, setPopcardcolor] = useState(state.popcardColor)
  const [status, setStatus] = useState(state.status)
  const [locationsigned, setLocationsigned] = useState(state.locationSigned)
  const formattedDate = moment(state.dateSigned).format('YYYY-MM-DD');
  const [datesigned, setDatesigned] = useState(formattedDate)
  const [signaturetype, setSignaturetype] = useState(state.signatureType)
  const [encapsulation, setEncapsulation] = useState(state.encapsulated)
  const [graded, setGraded] = useState(state.graded)
  const [gradenum, setGradenum] = useState(state.gradeNumber)
  const [gradecomp, setGradecomp] = useState(state.gradingCompany)

  const [funkopopseries, setFunkopopseries] = useState(state.funkoPopSeries)
  const [funkopopnumber, setFunkopopnumber] = useState(state.funkoPopNumber)
  const [funkopopname, setFunkopopname] = useState(state.funkoPopName)
  const [upcnumber, setUpcnumber] = useState(state.upcNumber)
  const [boxdatecode, setBoxdatecode] = useState(state.boxDateCode)
  const [signer, setSigner] = useState(state ? state.signer.map((e) => { return { value: e, label: e, __isNew__: true } }) : [])
  const [authenticationwitness, setAuthenticationwitness] = useState(state.authenticationWitness.id)
  const [gpsdata, setGpsdata] = useState(state.gpsMetaData)

  const [signaffidavit, setSignaffidavit] = useState(state.signAffidavit)
  const [videoorclip, setVideoorclip] = useState(state.videoOrClip)
  const [gradingpic, setGradingpic] = useState(state.gradingReportLink)

  const [authenticationguarentee, setAuthenticationGuarentee] = useState(state.authenticationGuarentee)
  const [rightimagebordorcolor, setRightimagebordorcolor] = useState(state.popSignerBordorColor)
  const [notes, setNotes] = useState(state.note)
  const [leftimagebordorcolor, setLeftimagebordorcolor] = useState(state.popPictureBordorColor)
  const [packagename, setPackagenme] = useState(state.authenticationService)

  useEffect(() => {
    GetAgents();
  }, []);

  const GetAgents = () => {
    setLoader(true)
    axiosInstance.get('agent',).then((response) => {
      setLoader(false)
      if (response.status === 200) {
        setAgents(response.data.data);
      } else {
        if (response.data.message === "") {
          console.log("else check msg")
        }
      }
    }).catch(error => {
      setLoader(false)
      console.log("error")
    });

  }

  const Authservics = (value) => {
    setPackagenme(value)
    let items = value === "silver" ? [''] : (value === 'gold' ? ['', ''] : (value === 'vip' ? ['', '', '', '', '', ''] : []))
    // console.log("item", items)
    setauthService(items)
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
      // const color = '#e31990';
      return {
        ...styles,
        backgroundColor: '#e31990',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#e31990',
        color: 'white',
      },
    }),
  };


  const Additem = () => {
    setPopsinger([...popsinger, {}]);
  }

  const handleRemoveClick1 = (e, index) => {
    if (e.id) {
      handleDelete(e.id, index)
    } else {
      popsinger.splice(index, 1)
      setPopsinger([...popsinger])
    }
  }

  const FileUpload = (e, index) => {
    // setPopsinger({...popsinger,index:e.target.files[0]  })
    const value = e.file.originFileObj;
    // Create a copy of the state array
    const updatedState = [...popsinger];
    // Update the specific object in the array
    updatedState[index] = { ...updatedState[index], file: value };
    // Update the state with the modified array
    setPopsinger(updatedState);
  }


  const FileUpload2 = (e, index) => {
    // setPopsinger({...popsinger,index:e.target.files[0]  })
    const value = e.file.originFileObj;
    // Create a copy of the state array
    const updatedState = [...authservice];
    // Update the specific object in the array
    updatedState[index] = { ...updatedState[index], file: value };
    // console.log("setauthService", updatedState)
    // Update the state with the modified array
    setauthService(updatedState);
  }

  const EditPopCard = () => {
    setLoader(true);
    const form = new FormData()
    form.append("stickerNumber", stickernumber)
    form.append("popcard", "POP CARD")
    form.append("forPass", "P.A.S.S")
    form.append("popcardColor", popcardcolor)
    form.append("authenticationService", packagename)
    form.append("status", status)
    form.append("locationSigned", locationsigned)
    form.append("dateSigned", datesigned)
    form.append("signatureType", signaturetype)
    form.append("funkoPopSeries", funkopopseries)
    form.append("funkoPopNumber", funkopopnumber)
    form.append("funkoPopName", funkopopname)
    form.append("upcNumber", upcnumber)
    form.append("boxDateCode", boxdatecode)
    const signers = signer.map((e) => { return e.value })
    form.append("signer", signers)
    form.append("authentication_witness_id", authenticationwitness)
    form.append("signAffidavit", signaffidavit)
    form.append("authenticationGuarentee", authenticationguarentee)

    form.append("videoOrClip", typeof videoorclip == 'object' ? videoorclip : '')
    if (packagename === "silver") {
      form.append("serviceOneImage", authservice[0].file || '')
    } else if (packagename === "gold") {
      form.append("serviceOneImage", authservice[0].file || '')
      form.append("serviceTwoImage", authservice[1].file || '')
    }
    else {
      form.append("serviceOneImage", authservice[0].file || '')
      form.append("serviceTwoImage", authservice[1].file || '')
      form.append("serviceThreeImage", authservice[2].file || '')
      form.append("serviceFourImage", authservice[3].file || '')
      form.append("serviceFiveImage", authservice[4].file || '')
      form.append("serviceSixImage", authservice[5].file || '')
    }
    let popsigners = popsinger.filter((e) => e.file);
    if (popsigners.length > 0) {
      popsigners.map((e) => {
        form.append("popSigner[][id]", e.id)
        form.append("popSigner[][file]", e.file)
        return true;
      })
    }
    form.append("leftImageBordorColor", leftimagebordorcolor)
    form.append("rightImageBordorColor", rightimagebordorcolor)
    // form.append("limit", limit)
    form.append("gpsMetaData", gpsdata)
    form.append("encapsulated", encapsulation)
    form.append("graded", graded)
    form.append("gradeNumber", gradenum)
    form.append("gradingCompany", gradecomp)
    form.append("gradingReportLink", gradingpic)
    form.append("note", notes)

    const header = { headers: { 'X-Http-Method-Override': 'PUT' } };
    axiosInstance.post(`popcard/${params.id}`, form, header).then((response) => {
      // console.log("check", response)
      setLoader(false);
      if (response.status === 200) {
        toast.success(response.data.message);
        navigate('/PopCards');
      } else {
        toast.error(response.data.message)
      }
    }).catch(error => {
      toast.error(error)
      setLoader(false)
    });
  }

  const handleDelete = (id, index) => {
    setLoader(true);
    axiosInstance.get(`delete/popsigner/${id}`).then((resp) => {
      setLoader(false);
      const Data = resp.data;
      if (Data.status) {
        popsinger.splice(index, 1)
        setPopsinger([...popsinger])
        toast.success(Data.message);
      } else {
        toast.error(Data.message)
      }
    }).catch(error => {
      toast.error(error)
      setLoader(false)
    });
  };

  const handleInputChange = (e, func) => {
    func(e.target.value)
  }

  return (
    <Fragment>
      {/* <PageTItle activeMenu="Element" motherMenu="Form" pageContent="Element" /> */}
      {loader === true ? <Loader fullPage loading /> : null}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>EDIT POP CARD</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>STICKER NUMBER</label>
                      <InputMask
                        className="form-control"
                        mask="a-999999"
                        placeholder="Enter Sticker Number"
                        onChange={(e) => handleInputChange(e, setStickernumber)}
                        value={stickernumber}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>POP CARD COLOR</label>
                      <input
                        type="color"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleInputChange(e, setPopcardcolor)}
                        value={popcardcolor}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>POP PICTURE BORDER COLOR</label>
                      <input
                        type="color"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setLeftimagebordorcolor)}
                        value={leftimagebordorcolor}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>POP SIGNER BORDER COLOR</label>
                      <input
                        type="color"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setRightimagebordorcolor)}
                        value={rightimagebordorcolor}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION SERVICE</label>
                      {/* <Select options={options} onChange={(e) => Authservics(e.value)} styles={{ background: 'linear-gradient(to right, #1d2333 0%, #454e69 100%)' }} /> */}
                      <select
                        onChange={(e) => Authservics(e.target.value)}
                        id="inputState"
                        className="form-control form-control-select"
                        value={packagename}
                      >
                        <option value="" >
                          Please select one option
                        </option>
                        <option value={"silver"}>Silver</option>
                        <option value={"gold"}>Gold</option>
                        <option value={"vip"}>VIP</option>
                      </select>
                    </div>
                    {authservice.map((v, i) => (
                      <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                        <label className="text-white" style={{ fontSize: 'medium' }}>POP PICTURE {i + 1}</label>
                        <div className="d-flex align-items-center mb-3">
                          <div className="form-group text-center">
                            {/* <input
                          type="file"
                          className="form-control"
                          placeholder=""
                          onChange={(e) => FileUpload2(e, i)}
                        /> */}
                            <ImageUploader onChange={(a) => FileUpload2(a, i)} file={v.file ? v.file : v} aspect={1080 / 1440} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
              <hr />
              <div className="row">
                {popsinger.map((e, i, arr) => (
                  <div className="col-sm-6 col-md-4 col-lg-3" key={i}>
                    <label className="text-white" style={{ fontSize: 'medium' }}>POP SIGNER PICTURE {i + 1} </label>
                    <div className="d-flex align-items-center mb-3">
                      <div className="form-group text-center">
                        {/* <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => FileUpload(e, i)}
                      /> */}
                        <ImageUploader onChange={(a) => FileUpload(a, i)} file={e.file ? e.file : e.pop_signer} aspect={1080 / 1440} />
                      </div>
                      <div className="d-flex">
                        <Button disabled={popsinger.length === 1} className="me-2" variant="primary" onClick={() => handleRemoveClick1(e, i)}>-</Button>
                        <Button disabled={popsinger.length === 15} hidden={i !== arr.length - 1} className="me-2" variant="primary" onClick={() => Additem()}>+</Button>
                      </div>
                    </div>
                  </div>
                )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>FUNKO POP DETAILS</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>FUNKO POP SERIES</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Pop Series"
                        onChange={(e) => handleInputChange(e, setFunkopopseries)}
                        value={funkopopseries}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>FUNKO POP NUMBER</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Funko Pop Number"
                        onChange={(e) => handleInputChange(e, setFunkopopnumber)}
                        value={funkopopnumber}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>FUNKO POP NAME</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Funko Pop Name"
                        onChange={(e) => handleInputChange(e, setFunkopopname)}
                        value={funkopopname}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>UPC NUMBER</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Upc Number"
                        onChange={(e) => handleInputChange(e, setUpcnumber)}
                        value={upcnumber}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>BOX DATE CODE</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Box Date Code"
                        onChange={(e) => handleInputChange(e, setBoxdatecode)}
                        value={boxdatecode}
                        maxLength={12}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>AUTHENTICATION DETAILS</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>STATUS</label>
                      <select
                        onChange={(e) => handleInputChange(e, setStatus)}
                        value={status}
                        className="form-control form-control-select"
                      >
                        <option value={"authenticated"}>AUTHENTICATED</option>
                        <option value={"pending"}>PENDING</option>
                        <option value="lost">
                          LOST
                        </option>
                        <option value={"stolen"}>STOLEN</option>
                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>LOCATION SIGNED</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Location Signed"
                        onChange={(e) => handleInputChange(e, setLocationsigned)}
                        value={locationsigned}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>DATE SIGNED</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setDatesigned)}
                        value={datesigned}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>SIGNED AFFIDAVIT</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Signed Affidavit"
                        onChange={(e) => handleInputChange(e, setSignaffidavit)}
                        value={signaffidavit}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>VIDEO OR CLIP</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setVideoorclip(e.target.files[0])}
                      // value={videoorclip}

                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION WITNESS</label>
                      <select
                        onChange={(e) => handleInputChange(e, setAuthenticationwitness)}
                        value={authenticationwitness}
                        className=" form-control form-control-select"
                      >
                        <option value="">
                          select agent
                        </option>
                        {loginUser.role.name === 'admin' && <option value={loginUser.role.id}>{loginUser.role.name}</option>}
                        {agents.map((e, i) => (
                          <option value={e.id} key={i}>{e.first_name + ' ' + e.last_name}</option>
                        ))}
                      </select>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Authentication Witness"
                        onChange={(e) => handleInputChange(e, setAuthenticationwitness)}
                        value={authenticationwitness}
                      /> */}
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>GPS META DATA</label>
                      <select
                        onChange={(e) => handleInputChange(e, setGpsdata)}
                        value={gpsdata}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="verified" selected>
                          VERIFIED
                        </option>
                        <option value={"n/a"}>N/A</option>
                      </select>

                    </div>

                    <div className="col-md-3 text-center mb-3 ">
                      <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                      <select
                        onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                        value={authenticationguarentee}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="" >
                          Please select one option
                        </option>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>

                      </select>
                    </div>

                    {/* <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                      <select

                        onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                        defaultValue={"option"}
                        id="inputState"
                        className="form-control form-control-select"
                        value={authenticationguarentee}
                      >
                        <option value="options" >
                          Please select one option
                        </option>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>

                      </select>
                    </div> */}





                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>SIGNATURE DETAILS</h4>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group mb-3 col-md-12 text-center">
                        <label style={{ fontSize: "larger", color: "white" }}>SIGNATURE TYPE</label>
                        <select
                          onChange={(e) => handleInputChange(e, setSignaturetype)}
                          value={signaturetype}
                          id="inputState"
                          className=" form-control form-control-select"
                        >
                          <option value="" >
                            Please select one option
                          </option>
                          <option value={"Single"}>Single</option>
                          <option value={"Multiple"}>Multiple</option>

                        </select>
                      </div>

                      {/* <div className="form-group mb-3 col-md-12 text-center">
                        <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                        <select

                          onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                          defaultValue={"option"}
                          id="inputState"
                          className="form-control"
                          value={authenticationguarentee}
                        >
                          <option value="option" disabled>
                            Please select one option
                          </option>
                          <option value={"yes"}>Yes</option>
                          <option value={"no"}>No</option>

                        </select>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>


              <div className="col-md-7 text-center mb-3 mt-4">
                <div className="form-group mb-3 col-md-12 text-center">
                  <label style={{ fontSize: "larger", color: "white" }}>SIGNER / SIGNERS</label>
                  <CreatableSelect isMulti styles={colourStyles} options={signer} defaultValue={signer} onChange={(e) => setSigner(e)} />
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* pop fax */}
        {/* <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>POP FAX</h4>
            </div>


            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>REGISTERED OWNER</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleInputChange(e, setOwner)}
                        value={owner}


                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>PURCHASE DATE</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleInputChange(e, setPurdate)}
                        value={purdate}


                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>PURCHASE PRICE</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleInputChange(e, setPurprice)}
                        value={purprice}


                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center mt-4">
                      <Button className="me-2" variant="primary" >
                        UPDATE
                      </Button>
                    </div>

                    <div className="form-group mb-3 col-md-12  mt-4">
                      <Button className="me-2" variant="primary" style={{ fontSize: "x-Large" }} >
                        TRANSFER TO PREVIOUS OWNERSHIP HISTORY
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>






          </div>
        </div> */}
        {/* pop fax */}

        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>ENHANCED DETAILS</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>ENCAPSULATED</label>
                      <select
                        onChange={(e) => handleInputChange(e, setEncapsulation)}
                        value={encapsulation}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="" >
                          Please select one option
                        </option>
                        <option value={"Yes"}>yes</option>
                        <option value={"No"}>No</option>

                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>GRADED</label>
                      <select
                        onChange={(e) => handleInputChange(e, setGraded)}
                        value={graded}
                        id="inputState"
                        className=" form-control form-control-select"
                      >
                        <option value="" >
                          Please select one option
                        </option>
                        <option value={"Yes"}>Yes</option>
                        <option value={"No"}>No</option>

                      </select>
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>GRADE NUMBER</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Grade Number"
                        onChange={(e) => handleInputChange(e, setGradenum)}
                        value={gradenum}
                        min={0}
                        max={10}
                      />
                    </div>

                    <div className="form-group mb-3 col-md-3 text-center ">
                      <label style={{ fontSize: "larger", color: "white" }}>GRADING COMPANY</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Grading Company"
                        onChange={(e) => handleInputChange(e, setGradecomp)}
                        value={gradecomp}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3 text-center ">
                      <label style={{ fontSize: "larger", color: "white" }}>GRADING REPORT LINK</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Gradeing Report Link"
                        onChange={(e) => handleInputChange(e, setGradingpic)}
                        value={gradingpic}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title" style={{ fontSize: "30px" }}>NOTES</h4>
            </div>


            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      {/* <label style={{ fontSize: "larger", color: "white" }}>Notes</label> */}
                      <textarea
                        className="textarea"
                        placeholder="Write a note of 200 letters"
                        value={notes}
                        rows={12}
                        maxLength={200}
                        style={{ width: "100%", padding: "1rem", borderRadius: '1rem' }}
                        onChange={(e) => handleInputChange(e, setNotes)}
                      />
                    </div>

                    {/* <div className="form-group mb-3 col-md-5 text-center">
                      <label style={{ fontSize: "larger", color: "white" }}>Limit</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => handleInputChange(e, setLimit)}
                        value={limit}
                        min={1}
                        max={1000}
                      />
                    </div> */}
                  </div>
                </form>
              </div>
            </div>


            {/* <div className="col-md-4 text-center mb-3 mt-4">
                <label style={{ fontSize: "larger", color: "white" }}>AUTHENTICATION GUARANTEE</label>
                <select
                  onChange={(e) => handleInputChange(e, setAuthenticationGuarentee)}
                  value={authenticationguarentee}
                  id="inputState"
                  className=" form-control form-control-select"
                >
                  <option value="options" >
                    Please select one option
                  </option>
                  <option value={"yes"}>Yes</option>
                  <option value={"no"}>No</option>
                </select>
              </div> */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <Button className="me-2" variant="primary" onClick={() => navigate("/PopCards")}>
              Back
            </Button>
            <Button className="me-2" variant="primary" onClick={() => EditPopCard()}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditPopcard;
