import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'
import BasicDatatable from '../table/BasicDatatable'
import axiosInstance from '../../../services/AxiosInstance';
import { toast } from 'react-toastify';
import { BsSearch } from 'react-icons/bs';
import { Loader } from 'react-overlay-loader';

export default function PopSearch() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [passNumber, setPassNumber] = useState("");
    const [popn, setPopn] = useState("");
    const [popSeries, setPopSeries] = useState("");
    const [popName, setPopName] = useState("");
    const [popSigner, setPopSigner] = useState("");



    const SearchData = () => {
        setLoader(true)
        axiosInstance.get(`popcard?stickerNumber=${passNumber}&popNumber=${popn}&popSeries=${popSeries}&popName=${popName}&popSigner=${popSigner}`,).then((response) => {
            const Data = response.data
            setLoader(false)
            if (Data.status) {
                toast.success(Data.message)
                setData(Data.popcard)
            } else {
                toast.error(Data.message)
            }
        }).catch(error => {
            toast.error(error)
            setLoader(false)
        });
    }

    return (<>
        {loader === true ? <Loader fullPage loading /> : null}
        <div className="card">
            <div className="card-header">
                <h4 className="card-title" style={{ fontSize: "30px" }}>FUNKO POP SEARCH</h4>
            </div>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-4 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>P.A.S.S NUMBER</label>
                                <ReactInputMask
                                    mask="a-999999"
                                    type="text"
                                    className="form-control"
                                    placeholder="S - 100250"
                                    value={passNumber}
                                    onChange={(e) => setPassNumber(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>POP NAME</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter POP Name"
                                    value={popName}
                                    onChange={(e) => setPopName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>POP NUMBER</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter POP Number"
                                    value={popn}
                                    onChange={(e) => setPopn(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>POP SERIES</label>
                                <input
                                    type="text"
                                    placeholder="Enter POP Series"
                                    className="form-control"
                                    onChange={(e) => setPopSeries(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-4 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>POP SIGNER</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter POP Signer"
                                    value={popSigner}
                                    onChange={(e) => setPopSigner(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-md-1 text-center">
                                <label style={{ fontSize: "larger", color: "white" }}>&nbsp;</label>
                                <Button variant="primary" onClick={() => SearchData()} disabled={passNumber === "" && popn === "" && popSeries === "" && popName === "" && popSigner === ""}><BsSearch size={25} /></Button>
                            </div>
                            {data.length > 0 ?
                                <BasicDatatable data={data} setData={setData} />
                                : null
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}
