import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'
import axiosInstance from '../../../services/AxiosInstance';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'react-overlay-loader';

export default function SingleClone() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState("");
    const [stickerNumber, setStickerNumber] = useState("");
    const [cloneNumber, setCloneNumber] = useState("");

    const onSingleClone = () => {
        setLoader(true)
        const form = new FormData()
        form.append("stickerNumber", stickerNumber)
        form.append("cloneStickerNumber", cloneNumber)
        axiosInstance.post('single/clone', form).then((response) => {
            setLoader(false)
            const Data = response.data.status
            if (Data) {
                toast.success(response.data.message)
                navigate("/PopCards")
            } else {
                toast.error(response.data.message)
            }
        }).catch(error => {
            toast.error(error)
            setLoader(false)
        });
    }
    return (<>
        {loader === true ? <Loader fullPage loading /> : null}
        <div className="card">
            <div className="card-header">
                <h4 className="card-title" style={{ fontSize: "30px" }}>SINGLE CLONE</h4>
            </div>
            <div className="card-body">
                <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row align-items-center">
                            <div className="form-group col-md-6">
                                <label style={{ fontSize: "larger", color: "white" }}>P.A.S.S NUMBER</label>
                                <ReactInputMask
                                    mask="a-999999"
                                    type="text"
                                    className="form-control"
                                    placeholder="S - 100250"
                                    value={stickerNumber}
                                    onChange={(e) => setStickerNumber(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label style={{ fontSize: "larger", color: "white" }}>NEW P.A.S.S NUMBER</label>
                                <ReactInputMask
                                    mask="a-999999"
                                    type="text"
                                    className="form-control"
                                    placeholder="S - 100250"
                                    value={cloneNumber}
                                    onChange={(e) => setCloneNumber(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label style={{ fontSize: "larger", color: "white" }}>&nbsp;</label>
                                <Button variant="primary" className='w-100' disabled={stickerNumber.length === 0} onClick={() => onSingleClone()}> CLONE SINGLE</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}
