import React, { useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
// import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile from "../../../images/avatar/1.png";
// import avatar from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Modal } from "react-bootstrap";
import LogoutPage from './Logout';
import { toast } from "react-toastify";
import axiosInstance from "../../../services/AxiosInstance";
import { FiSettings } from 'react-icons/fi';
import { Loader } from "react-overlay-loader";
import { FaExchangeAlt } from 'react-icons/fa';



const Header = ({ onNote }) => {
	const [basicModal, setBasicModal] = useState(false);
	const [loader, setLoader] = useState(false);
	const [opass, setOpass] = useState("");
	const [newpass, setNewpass] = useState("");
	const [cpass, setCpass] = useState("");

	const user = JSON.parse(localStorage.getItem('user'));

	const handleInputChange = (e, func) => {

		func(e.target.value)
	}

	const ChangePass = () => {

		if (newpass !== cpass) {
			toast.error("newpassword and confirm password not match")

		}
		else {


			setLoader(true)
			const form = new FormData()
			form.append("o_password", opass)
			form.append("password", newpass)
			form.append("password_confirmation", cpass)

			axiosInstance.post('change-password', form).then((response) => {
				setLoader(false)
				if (response.status === 200) {
					toast.success(response.data.message)
					setBasicModal(false)
				} else {
					toast.error(response.data.message)
				}
			}).catch(error => {
				toast.error(error)
				setLoader(false)

			});
		}
	}

	return (
		<>
		 {loader === true ? <Loader fullPage loading /> : null}
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize", }}
							>
								<Button variant="primary" style={{ borderRadius: "50%", height: "50px", width: "54px" }}>
									<FiSettings size={25} />
								</Button>
							</div>
						</div>
						<ul className="navbar-nav header-right main-notification">
							<Dropdown as="li" className="nav-item dropdown header-profile">
								<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
									<img src={profile} width={20} alt="" />
									<div className="header-info ms-3">
										<span style={{ textTransform: 'capitalize' }}>{user?.first_name + " " + user?.last_name}</span>
										<small>{user?.role?.name}</small>
									</div>
								</Dropdown.Toggle>

								<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
									<Link to="/PopCards" className="dropdown-item ai-icon">
										<i className="flaticon-013-checkmark"></i>

										<span className="ms-2">PopCards </span>
									</Link>
									<Link to="#" onClick={() => setBasicModal(true)} className="dropdown-item ai-icon">
										<FaExchangeAlt/>

										<span className="ms-2">ChangePassword</span>
									</Link>

									<LogoutPage />
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</nav>
			</div>
			<Modal className="fade" show={basicModal}>
				<Modal.Header>
					<Modal.Title>Change Password</Modal.Title>
					<Button
						variant=""
						className="btn-close"
						onClick={() => setBasicModal(false)}
					>

					</Button>
				</Modal.Header>
				<Modal.Body>
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="row">

											<div className="form-group mb-3 col-md-12 ">
												<label style={{ fontSize: "larger", color: "white" }}>Old Password</label>
												<input
													type="text"
													className="form-control"
													placeholder="Enter Your Old Password"
													onChange={(e) => handleInputChange(e, setOpass)}
													value={opass}

												/>
											</div>
											<div className="form-group mb-3 col-md-12 ">
												<label style={{ fontSize: "larger", color: "white" }}>New Password</label>
												<input
													type="password"
													className="form-control"
													placeholder="Enter New Password"
													onChange={(e) => handleInputChange(e, setNewpass)}
													value={newpass}

												/>
											</div>
											<div className="form-group mb-3 col-md-12 ">
												<label style={{ fontSize: "larger", color: "white" }}>Confirm Password</label>
												<input
													type="password"
													className="form-control"
													placeholder="Enter Confirm New Password"
													onChange={(e) => handleInputChange(e, setCpass)}
													value={cpass}

												/>
											</div>



										</div>


									</form>


								</div>

							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => setBasicModal(false)}
						variant="danger light"
					>
						Close
					</Button>
					<Button variant="primary" onClick={() => ChangePass()}>Update</Button>
				</Modal.Footer>
			</Modal>
		</div>

		</>
	);
};

export default Header;
