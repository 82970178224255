import React, { useContext, useEffect, useState } from 'react';
//Import
import { Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Loader } from "react-overlay-loader";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from "../../../context/ThemeContext";
import axiosInstance from "../../../services/AxiosInstance";
import MultipleClone from '../MultipleClone';
import PopSearch from '../PopSearch';
import SingleClone from '../SingleClone';

// const AnalyticsDonut = loadable(() =>
// 	pMinDelay(import("./Dashboard/AnalyticsDonut"), 1000)
// );


const DashboardDark = () => {
	const { changeBackground } = useContext(ThemeContext);
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [totalPopcard, setTotalPopcard] = useState([])
	const [passNum, setPassNum] = useState("");
	const Role = JSON.parse(localStorage.getItem("user"))

	useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
		GetData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const GetData = () => {
		setLoader(true)
		axiosInstance.get('count').then((response) => {
			setLoader(false);
			if (response.status === 200) {
				// console.log("count", response)
				setTotalPopcard(response.data.popcard);
			} else {
				if (response.data.message === "") {
					console.log("else check msg")
				}
			}
		}).catch(error => {
			console.log("error")
		});

	}

	const GetPopcard = () => {
		setLoader(true)
		axiosInstance.get(`search/${passNum}`,).then((response) => {
			const Data = response.data;
			setLoader(false)
			if (Data.status) {
				toast.success(Data.message)
				navigate(`/EditPopcard/${Data.result.id}`, { state: Data.result })
			} else {
				toast.error(response.data.message)
			}
		}).catch(error => {
			toast.error(error)
			setLoader(false)
		});
	}

	const ExportAllPopcards = () => {
		setLoader(true)
		axiosInstance.get('all/export').then((response) => {
			setLoader(false);
			const Data = response.data;
			if (Data.status) {
				ExportDecodeExcelFile(Data.base64, 'All-Popcards');
				toast.success(Data.message)
			} else {
				toast.warn(Data.message)
			}
		})
	}

	const ExportDecodeExcelFile = (url, filename) => {
		var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${url}`;
		var a = document.createElement('a');
		a.href = mediaType;
		a.setAttribute('download', `${filename}.xlsx`);
		document.body.appendChild(a);
		a.click();
	}

	const ExportDB = () => {
		setLoader(true)
		axiosInstance.get('db-backup').then((response) => {
			setLoader(false);
			const Data = response.data;
			if (Data.status) {
				// console.log(Data.file)
				var a = document.createElement('a');
				a.href = Data.file;
				a.setAttribute('download', `db.xlsx`);
				document.body.appendChild(a);
				a.click();
				toast.success(Data.message)
			} else {
				toast.warn(Data.message)
			}
		}).catch((error) => {
			setLoader(false);
			console.log('error', error)
		})
	}

	return (
		<>
			{loader === true ? <Loader fullPage loading /> : null}
			<div className="row">
				<div className="col-xl-4 col-sm-6">
					<div className="card gradient-14 card-bx">
						<div className="card-body d-flex align-items-center">
							<div className="me-auto text-white">
								<h2>{totalPopcard}</h2>
								<p className="fs-18">TOTAL POPCARDS</p>
								<Link to={'/AddPopcard'}><Button variant="primary">Add Popcard</Button></Link>
							</div>
							<i className="flaticon-013-checkmark" style={{ fontSize: "40px", color: "white" }}></i>
						</div>
					</div>
				</div>
				<div className="col-xl-4 col-sm-6">
					<div className="card gradient-5 card-bx">
						<div className="card-body d-flex align-items-center">
							<div className="me-auto text-white">
								<div className="form-group mb-3 col-md-10">
									<label style={{ fontSize: "larger", color: "white" }}>P.A.S.S NUMBER</label>
									<InputMask
										mask="a-999999"
										type="text"
										className="form-control"
										placeholder="S - 100250"
										value={passNum}
										onChange={(e) => setPassNum(e.target.value)}
									/>
								</div>
								<Button variant="primary" onClick={GetPopcard} disabled={!passNum}>Quick Edit</Button>
							</div>
							<i className="flaticon-063-pencil" style={{ fontSize: "40px", color: "white" }}></i>
						</div>
					</div>
				</div>
				{Role.role.name === "admin" &&
					<div className="col-xl-4 col-sm-6">
						<div className="card gradient-4 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<div className="form-group mb-3 col-md-10">
										<h3>Export Data</h3>
									</div>
									<Button variant="primary" onClick={ExportDB}>DB Download</Button>
									<Button className="mt-2" variant="primary" onClick={ExportAllPopcards}>Download Popcards</Button>
								</div>
								<i className="flaticon-028-download" style={{ fontSize: "40px", color: "white" }}></i>
							</div>
						</div>
					</div>
				}
				{/* Pop Search */}
				<div className="col-xl-12 col-lg-12">
					<PopSearch />
				</div>

				{/* Single Clone */}
				<div className="col-xl-6 col-lg-6">
					<SingleClone />
				</div>

				{/* Clone Range */}
				<div className="col-xl-6 col-lg-6">
					<MultipleClone />
				</div>
			</div>
		</>
	)
}
export default DashboardDark;